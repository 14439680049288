<template>
    <div class="mx-3">
      <h2 class="px-5 py-5">{{ $t('Results Report') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-text-field  :dark="$store.state.isDarkMode"  :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode"  :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
            <v-autocomplete 
          v-model="filters.test"
            label="اسم الاختبار"
            clearable :items="tests" :loading="testsLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchTestsAutoComplete($event)"
            >
          </v-autocomplete>
        </v-col>
        <v-col md="3">
            <v-autocomplete 
          v-model="filters.student"
            :label="$t('student name')"
            clearable :items="childrens" :loading="childrensLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchRegRecordAutoComplete($event)"
            >
          </v-autocomplete>
        </v-col>
        <v-col md="3">
            <v-autocomplete 
          v-model="filters.subject"
            :label="$t('subject')"
            clearable :items="subjects" :loading="subjectLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchSubjectAutoComplete($event)"
            >
          </v-autocomplete>
        </v-col>
        <v-col md="3">
            <v-text-field   :label="$t('Partition')" 
                            v-model="filters.partition" clearable>
          </v-text-field>
           
        </v-col>
        <v-col md="3">
            <v-autocomplete v-model="filters.term" :loading="termsLoading" :items="terms"
          :label="$t('term')"  item-text="name" item-value="id"  @keypress="fetchTermsAutoComplete($event)"
          color="#757575"></v-autocomplete>
        
        </v-col>
        <v-col md="3">
            <v-autocomplete
              color="#757575"
              :loading="eduClassesLoading"
              item-text="name"
              item-value="id"
              :label="$t('educitional class')"
              v-model="filters.edu_class"
              :items="eduClasses"
              clearable
            ></v-autocomplete>
        </v-col>
        <v-col md="3">
        <v-autocomplete
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="gradesLoading"
                :label="$t('class')"
                v-model="filters.grade"
                :items="grades"
                clearable
            ></v-autocomplete>
          </v-col>
          
          <v-col md="3">
            <v-autocomplete
                color="#757575" 
                :label="$t('Group By')"
                :items="group_by"
                item-text="name"
                item-value="value"
                v-model="filters.group_by"
                
              ></v-autocomplete>
          </v-col>
          <v-col md="5">

          </v-col>

        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0" v-if="returnAbility('mark:export')">
          <v-btn color="blue" dark block  @click="exportToExcel(filters)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
      </v-row>
  


      <v-container id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!loading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
          <template  v-for="data in tableItems" >
            <tr :key="data.uid" style=" background: var(--group-by-col)">
              
              <th colspan="11" style=" border: 0; font-size: 1rem">
                <span v-if="data.key"> {{ data.key }} </span>
              </th>
              
            </tr>
            <tr v-for="(item, idx) in data.value" :key="idx">
              
              <td>{{ item.date }}</td>
              <td>{{ item.student_name }}</td>
              <td>{{ item.subject }}</td>
              <td>{{ item.partition }}</td>
              <td>{{ item.test}}</td>
              <td>{{ item.max_mark }}</td>
              <td>{{ item.mark }}</td>
              <td>{{ item.label }}</td>
              <td>{{ item.test_percent }}%</td>
              <td>{{ item.edu_class }}</td>
              <td>{{ item.term }}</td>
             
              
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
      <div class="d-flex justify-end mt-5">
        <v-row>
          <v-col md="5 ">
            <v-switch
              @change="goToAll"    
              v-model="is_all"
              color="green"
              :label="$t('Show All')"
            ></v-switch>
          </v-col>
        <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total" :page="page"
          @PaginationValue="applyPagination($event)"></app-pagination>
        </v-row>
      </div>
    </v-container>
   
    </div> 
  </template>
  
<script>
import axios from 'axios'
import debounce from "../../../../../helpers/debounce";
import spinner from "../../../../../components/base/spinner.vue";
import pagination from "../../../../../components/base/pagination.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";


import { saveAs } from 'file-saver';

  
  export default {
    components: {
    appSpinner: spinner,
    appPagination: pagination,
    AutoCompleteField,
  },
    data: () => ({
      is_all: false,
      paginated: true,
      grades: null,
      gradesLoading: false,
      eduClasses: null,
      eduClassesLoading: false,
      childrens: null,
      childrensLoading: false,
      tableItems: [],
      mealSearch: null,
      loading: false,
      page: 1,
      total: 1,
      tableHeaders: ["date", "student name", "subject","Partition", "Test Name","maximum mark","Mark","Label","Test Percent" ,"educitional class","term"],
      items: [],
        subjects:[],
        testsLoading: false,
        tests:[],
        subjectLoading: false,
        terms:[],
        termsLoading: false,
      group_by: [
        {
          name: "الشعبة",
          value: "edu_class",
        },
        {
          name: "المادة",
          value: "subject",
        },
        {
          name: "الاختبار",
          value: "test_name",
        },
        {
          name: "اسم الطالب",
          value: "student_name",
        },
        {
          name: "الفصل",
          value: "term",
        },
      ],
      filters: {
        student: null,
        edu_class: null,
        grade: null,
        term: null,
        from: null,
        to: null,
        test: null,
        subject: null,
        partition: null,
        group_by: "test_name",
      },
    }),
    computed: {
    mealItems() {
      return meal.mealData;
    },
  },
  watch:{
  
  },
    methods: {
      goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.getAll();
    },
      async fetchEduClasses() {
      try {
        this.eduClassesLoading = true;
        const eduClasses = await axios.get("/edu-class",{
          params: {
            paginated: false,
          }
        });
        this.eduClasses = eduClasses.data.classes;
      } catch (err) {
      } finally {
        this.eduClassesLoading = false;
      }
    },
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.childrens = searchResult.data.data;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
    async fetchTestsAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.testsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/test", {
            params: {
              name: searchValue,
            },
          });
          this.tests = searchResult.data.tests;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.testsLoading = false;
        }
      }, 1000)();
    },
    async fetchSubjectAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.subjectLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/subject", {
            params: {
              name: searchValue,
            },
          });
          this.subjects = searchResult.data.subjects;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.subjectLoading = false;
        }
      }, 1000)();
    },
    async fetchTermsAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.termsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/terms", {
            params: {
              name: searchValue,
            },
          });
          this.terms = searchResult.data.terms;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.termsLoading = false;
        }
      }, 1000)();
    },
     async exportToExcel(queryParams){
         
          try {
     
      const excel = await axios.get(`/mark/export`, {
        params: {
          ...queryParams,
          
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      

    } catch (err) {
      console.log('err', err);
    } finally {
      
    }
      
      },
   
      clearFilter() {
        this.filters.student =  undefined;
        this.filters.edu_class = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.test = undefined; 
        this.filters.grade = undefined;
        this.filters.partition = undefined;
        this.filters.subject = undefined;
        this.filters.term = undefined;
        
        this.page = 1;
        this.getAll();
      },
   
      async getAll() {
        try {
         
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          
          const res = await axios.get("/mark/report", {
            params: {
              size: 10,
              page: this.page,
              paginated: this.paginated,
              ...filterTemp,
            },
          });
          const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
            console.log(res.data.data);
            this.tableItems.forEach((item) => {
          item.uid = this.$uuid.v1();
        });
          this.total = res.data.total;
          this.page = res.data.current_page;
        } finally {
          this.loading = false;
        }
      },
      applyPagination(paginationValue) {
      this.page = paginationValue;
      this.getAll();
    },
    
 
    },
    async created() {
      this.getAll();
      this.fetchEduClasses();
      try {
      this.gradesLoading = true;
      const response = await axios.get("/grade", {
        params: {
          size: 100000,
        },
      });
      this.grades = response.data.grades;
    } catch (err) {
      console.log("err", err);
    } finally {
      this.gradesLoading = false;
    }
      
    },
  };
  </script>
  
  <style></style>
  